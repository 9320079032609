@font-face {
    font-family: 'Baloo Bhai 2';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Baloo Bhai 2 Regular'), local('BalooBhai2-Regular'), url(BalooBhai2-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Baloo Bhai 2';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Baloo Bhai 2 Bold'), local('BalooBhai2-Bold'), url(BalooBhai2-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(Roboto-LightItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto Italic'), local('Roboto-Italic'), url(Roboto-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(Roboto-BoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'), url(Roboto-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto-Regular'), url(Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(Roboto-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url(RobotoCondensed-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(RobotoCondensed-Bold.ttf) format('truetype');
}